<template>
  <div class="profile-data-wrapper financial-graphs connects" v-show="!loading">
    <template
      v-if="
        userPermissions &&
        userPermissions.financial_graphs &&
        userPermissions.financial_graphs.length > 0 &&
        userPermissions.financial_graphs.includes('View')
      "
    >
      <div class="profile-options">
        <div class="row">
          <div class="col">
            <router-link
              class="btn primary-btn"
              :to="{ name: 'FinancialGraphs' }"
              ><font-awesome-icon :icon="['fas', 'arrow-left']"
            /></router-link>
          </div>
          <!-- <div
            class="
              col
              flex-grow-1
              d-flex
              align-items-center
              justify-content-end
            "
          >
            <a
              class="links"
              href="javascript:void(0);"
              @click.prevent="openSideModal('addConnect')"
              v-if="userPermissions.financial_graphs.includes('Add')"
              >+ Add Connect</a
            >
          </div> -->
        </div>
        <div class="row">
          <div class="col"></div>
          <div class="col flex-grow-1 d-flex justify-content-end">
            <Select2
              id="filter-period"
              :options="filters.period.types"
              v-model="filters.period.value"
              class="select2-box"
              :resetOptions="false"
            />
          </div>
          <!-- <div class="col">
            <Select2
              id="filter-connect-types"
              v-model="filters.connect.value"
              :options="filters.connect.types"
              class="select2-box"
              :resetOptions="false"
              :placeholder="`Connect Type`"
            />
          </div> -->
        </div>
      </div>
      <div class="connects-chart equal-col">
        <h2 class="section-heading text-transform-uppercase">
          Membership Fee {{ filters.connect.value }}
        </h2>
        <template
          v-if="
            !connectData ||
            connectData.reduce((partialSum, a) => partialSum + a.z, 0) === 0
          "
        >
          <div
            class="
              no-data-wrap
              d-flex
              align-items-center
              justify-content-center
            "
          >
            <img src="@/assets/images/no-graph.png" alt="no-graph" />
            <div class="desc">No data<br />to show</div>
          </div>
        </template>
        <template v-else>
          <chart-js
            :type="`bar`"
            :datasets="connectChart.datasets"
            :labels="[]"
            :options="connectChart.options"
          />
        </template>
      </div>
      <div class="row grid-1" style="display:none;">
        <div class="col">
          <div class="custom-table no-arrows">
            <table id="datatable">
              <thead>
                <tr>
                  <th>Time Period</th>
                  <th>Membership Fee($)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(connect, index) in connectData" :key="index">
                  <td>{{ connect.x }}</td>
                  <td>{{ Math.round(connect.y) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="profile-footer d-flex justify-content-end">
        <button class="primary-btn" type="button" @click="downloadCsv()">
          <font-awesome-icon :icon="['fa', 'download']" /> Download Report
        </button>
      </div>
      <SideModal
        @close="closeSideModal"
        v-bind:contentClass="['sm-side--modal']"
        v-show="sideModal.connect.add.show"
      >
        <template v-slot:header> Add Connect </template>
        <template v-slot:body>
          <add-connect
            @refreshList="getMembershipFee()"
            @close="closeSideModal"
            v-if="sideModal.connect.add.show"
          />
        </template>
      </SideModal>
    </template>
    <template
      v-if="
        userPermissions &&
        userPermissions.financial_graphs &&
        (userPermissions.financial_graphs.length === 0 ||
          (userPermissions.financial_graphs.length > 0 &&
            !userPermissions.financial_graphs.includes('View')))
      "
      >Permission Denied.</template
    >
  </div>
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronRight,
  faDownload,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
library.add(faChevronRight, faDownload, faArrowLeft);

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";
import Select2 from "../components/Select2.vue";
import '@vuepic/vue-datepicker/dist/main.css'
import ChartJs from "../components/ChartJs.vue";
import financialMembershipFeeService from "../services/financialMembershipFee.service";
import common from "../mixins/common";
import SideModal from "../components/SideModal.vue";
import AddConnect from "../components/AddConnect.vue";

export default {
  components: { Select2, FontAwesomeIcon, ChartJs, SideModal, AddConnect },
  mixins: [common],
  data() {
    return {
      loading: true,
      filters: {
        period: {
          types: ["Monthly", "Annually"],
          value: "Monthly",
        },
        connect: {
          types: ["Purchased", "Awarded", "Refunded"],
          value: "Purchased",
        },
      },
      connectData: [],
      connectDataTB: [],
      connectChart: {
        datasets: [
          {
            label: "Membership Fee",
            data: [],
            tension: 0,
            fill: false,
            barBorderColor: "#6fda45",
            barBackgroundColor: "#6fda45",
            type: "bar",
            barThickness: 60,
            barPercentage: 60,
          },
        ],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          hover: { mode: null },
          layout: {
            padding: {
              top: 30,
              bottom: 30,
            },
          },
          plugins: {
            legend: false,
            title: false,
            datalabels: {
              backgroundColor: function (context) {
                return context.dataset.backgroundColor;
              },
              borderRadius: 4,
              color: "#fff",
              font: {
                weight: "400",
                size: '16px "Montserrat", sans-serif',
              },
              padding: 0,
              formatter: function (value) {
                return `${Math.round(value.y)}`;
              },
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              tricks: {
                reverse: true,
              },
            },
            y: {
              grid: {
                drawBorder: false,
                display: true,
              },
              min: 0,
              ticks: {
                stepSize: 10,
              },
              title: {
                display: true,
                text: "Connects Utilized",
                font: {
                  color: "#333",
                  weight: "600",
                  size: '18px "Montserrat", sans-serif',
                },
              },
            },
          },
        },
      },
      sideModal: {
        connect: {
          add: {
            show: false,
          },
        },
      },
    };
  },
  async mounted() {
    await this.getMembershipFee();
    this.loading = false;
  },
  methods: {
    async getMembershipFee() {
      let vm = this;
      const userPermissions = await vm.userPermissions;
      if (
        userPermissions &&
        userPermissions.financial_graphs &&
        userPermissions.financial_graphs.length > 0
      ) {
        try {
          vm.$store.commit("loadingStatus", true);
          const response = await financialMembershipFeeService.get(
            vm.filters.period.value
          );
          const connects = response.membership_fee;

          const tempData = connects.slice().reverse();

          vm.connectData = tempData;
          vm.connectDataTB = response.membership_fee;
          vm.connectChart.datasets[0].data = connects;

          const connectSum = vm.connectData.reduce(
            (partialSum, a) => partialSum + parseInt(a.y),
            0
          );
          vm.connectChart.options.scales.y.ticks.stepSize = Math.round(
            Math.round(connectSum) / 4
          );

          $("#datatable").DataTable().destroy();

          vm.$nextTick(function () {
            $("#datatable").DataTable({
              dom: "Bfrtip",
              buttons: [
                {
                  extend: "csv",
                  filename: "Membership Fees "+vm.filters.period.value,
                  extension: ".csv",
                },
              ],
              iDisplayLength: 25,
              bLengthChange: false,
              order: [[0, "desc"]],
              ordering: false,
              info: false,
              fnDrawCallback: function (oSettings) {
                if (oSettings._iDisplayLength >= oSettings.fnRecordsDisplay()) {
                  $(oSettings.nTableWrapper)
                    .find(".dataTables_paginate")
                    .hide();
                } else {
                  $(oSettings.nTableWrapper)
                    .find(".dataTables_paginate")
                    .show();
                }
              },
            });
            $(".dt-buttons, .dataTables_filter").hide();
          });
          vm.$store.commit("loadingStatus", false);
        } catch (error) {
          const message = vm.errorMessage(error);
          vm.toastMessage(message, "error");
          vm.$store.commit("loadingStatus", false);
        }
      }
    },
    downloadCsv: async function () {
      $(".buttons-csv").click();
    },
    closeSideModal() {
      document.body.classList.remove("lmodal-open");
      setTimeout(() => {
        this.sideModal.connect.add.show = false;
      }, 300);
    },
    async openSideModal(action) {
      let vm = this;

      await vm.$store.dispatch("getUserDetails");

      if (
        vm.userPermissions &&
        vm.userPermissions.financial_graphs &&
        vm.userPermissions.financial_graphs.length > 0 &&
        vm.userPermissions.financial_graphs.includes("View")
      ) {
        if (
          action === "addConnect" &&
          vm.userPermissions.financial_graphs.includes("Add")
        ) {
          vm.sideModal.connect.add.show = true;
        }
        setTimeout(() => {
          document.body.classList.add("lmodal-open");
        }, 300);
      }
    },
  },
  watch: {
    filters: {
      async handler() {
        await this.getMembershipFee();
      },
      deep: true,
    },
    userPermissions: {
      handler() {
        this.getMembershipFee();
      },
    },
  },
};
</script>
